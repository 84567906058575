import * as React from "react"

//import Layout from "../components/layout"
//import SEO from "../components/seo"

/* eslint-disable import/no-extraneous-dependencies */
import ReactFullpage from '@fullpage/react-fullpage'

const fullpageOptions = {
  anchors: ['firstPage', 'secondPage', 'thirdPage', 'fouthPage', 'fifthPage', 'sixthPage'],
  sectionsColor: ['#282c34', '#ff5f45', '#0798ec','#282c34', '#ff5f45', '#0798ec'],
  callbacks: ['onLeave', 'afterLoad'],
}

const FullpageWrapper = fullpageProps => (
  <ReactFullpage
    {...fullpageOptions}
    render={({ state, fullpageApi }) => {
      console.log('render prop change', state) // eslint-disable-line no-console

      if (state.callback === 'onLeave') {
        if (state.direction === 'down') {
          console.log('going down...' + state.origin.index)
        }
      }
      return (
        <div id="fullpage-wrapper">
          <div className="section section1">
            <h3>Section 1</h3>
            <button onClick={() => fullpageApi.moveSectionDown()}>
              Move down
            </button>
          </div>
          <div className="section">
            <div className="slide">
              <h3>Slide 2.1</h3>
            </div>
            <div className="slide">
              <h3>Slide 2.2</h3>
            </div>
            <div className="slide">
              <h3>Slide 2.3</h3>
            </div>
          </div>
          <div className="section">
            <h3>Section 3</h3>
          </div>
          <div className="section">
            <h3>Section 4</h3>
            <button onClick={() => fullpageApi.moveSectionDown()}>
              Move down
            </button>
          </div>
          <div className="section">
            <h3>Section 5</h3>
          </div>
          <div className="section">
            <h3>Section 6</h3>
          </div>
        </div>
      )
    }}
  />
)

export default FullpageWrapper





// const ContactPage = () => {

//  return (
//   <Layout>
//   <SEO title="Contact" />
//   <div>
//       {/* <div className="title">Contact</div> */}
      
//  <h1>Contact</h1>
//  {/* <p className="leader">Alt tags are the easiest attributes to miss off an image; they’re also super easy for automated tests to find and flag up. This post aims to dispel some untruths about the humble alt text. </p>
//  <p>
//  With the saturation of IDEs and the addition of helpful code-completes and guidance, alternative text has become the poster child of accessibility validation. (It’s also the first item on the WCAG list - so it’s gotta be pretty important) 
//  </p> <p>
// Pastrami buffalo kielbasa shankle jowl ham hock chislic shoulder filet mignon tenderloin jerky pork belly shank ribeye capicola. Biltong pork drumstick pastrami meatball, bresaola strip steak pork chop t-bone frankfurter ham hock corned beef doner porchetta cupim. Bacon beef ribs fatback shankle venison chuck pancetta cow sirloin. Boudin beef jowl brisket, shankle meatball jerky turducken frankfurter ham hock cow.
// </p> <p>
// T-bone chuck ham hock alcatra burgdoggen drumstick pork chop bresaola. Pork chop ball tip sirloin pig landjaeger. Beef prosciutto pancetta, andouille short ribs filet mignon chicken. Beef ribs drumstick tri-tip ribeye burgdoggen, bresaola flank t-bone venison chicken andouille.
//  </p> */}

 
// </div> 
// </Layout>
//  ); 

// };



// export default ContactPage;
